
import TextField from "@mui/material/TextField";
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { moment, format } from "moment"
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
export const PhoneFields = ({ fetchedData, onChangeHandler }) => {
   return (
      <>
         {fetchedData.component && (
            <div className="form">
               <h2>Network</h2>
               <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-4">
                  <TextField
                     key="network"
                     required
                     id="outlined-required"
                     //className="input"
                     label="Sítě"
                     name="network"
                     InputLabelProps={{
                        shrink: true,
                     }}
                     value={fetchedData.component.network}
                     onChange={(e) => onChangeHandler(e, true)}
                  />
                  <TextField
                     key="speed"
                     required
                     id="outlined-required"
                     //className="input"
                     label="Rychlosti"
                     name="speed"
                     InputLabelProps={{
                        shrink: true,
                     }}
                     value={fetchedData.component.speed}
                     onChange={(e) => onChangeHandler(e, true)}
                  />
                  <TextField
                     key="sim"
                     required
                     id="outlined-required"
                     //className="input"
                     label="SIM"

                     InputLabelProps={{
                        shrink: true,
                     }}
                     name="sim"
                     value={fetchedData.component.sim}
                     onChange={(e) => onChangeHandler(e, true)}
                  />
               </div>
               <h2>Uvedení na trh</h2>
               <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-4">

                  <TextField
                     id="date"
                     label="Announced"
                     type="date"
                     key="announced"
                     name="announced"
                     defaultValue={fetchedData.component.announced}
                     sx={{ width: 320 }}
                     InputLabelProps={{
                        shrink: true,
                     }}
                     onChange={(e) => onChangeHandler(e, true)}
                  />
                  <TextField
                     key="status"
                     required
                     id="outlined-required"
                     className="input"
                     label="Uvedení na trh"
                     name="status"
                     value={fetchedData.component.status}
                     onChange={(e) => onChangeHandler(e, true)}
                  />
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                     <DesktopDatePicker
                        id="date"
                        label="Released"
                        type="date"
                        key="released"
                        name="released"
                        //defaultValue={fetchedData.component.released}
                        inputFormat="DD / MM / YYYY"
                        value={fetchedData.component.released}
                        onChange={(e) => {
                           let date = e.toISOString().split("T")[0]
                           const obj = {
                              target: { name: "released", value: date }
                           }
                           onChangeHandler(obj, true)
                        }}
                        renderInput={(params) => <TextField {...params} />}
                     />
                  </LocalizationProvider>
               </div>

               <h2>Hardware/Sofware</h2>
               <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-4">
                  <TextField
                     key="memory"
                     required
                     id="outlined-required"
                     className="input"
                     label="Pamět - disk (BEZ JEDNOTEK)"
                     name="memory"
                     value={fetchedData.component.memory}
                     onChange={(e) => onChangeHandler(e, true)}
                  />
                  <TextField
                     key="ram"
                     required
                     id="outlined-required"
                     className="input"
                     label="RAM (BEZ JEDNOTEK)"
                     name="ram"
                     value={fetchedData.component.ram}
                     onChange={(e) => onChangeHandler(e, true)}
                  />
                  <TextField
                     key="os"
                     required
                     id="outlined-required"
                     className="input"
                     label="Operační systém"
                     name="os"
                     value={fetchedData.component.os}
                     onChange={(e) => onChangeHandler(e, true)}
                  />
                  <TextField
                     key="chipset"
                     required
                     id="outlined-required"
                     className="input"
                     label="Chipset"
                     name="chipset"
                     value={fetchedData.component.chipset}
                     onChange={(e) => onChangeHandler(e, true)}
                  />
                  <TextField
                     key="cpu"
                     required
                     id="outlined-required"
                     className="input"
                     label="CPU"
                     name="cpu"
                     value={fetchedData.component.cpu}
                     onChange={(e) => onChangeHandler(e, true)}
                  />
                  <TextField
                     key="gpu"
                     required
                     id="outlined-required"
                     className="input"
                     label="GPU"
                     name="gpu"
                     value={fetchedData.component.gpu}
                     onChange={(e) => onChangeHandler(e, true)}
                  />
                  <TextField
                     key="memoryType"
                     required
                     id="outlined-required"
                     className="input"
                     label="Typ paměti"
                     name="memoryType"
                     value={fetchedData.component.memoryType}
                     onChange={(e) => onChangeHandler(e, true)}
                  />
                  <TextField
                     key="memorySlot"
                     required
                     id="outlined-required"
                     className="input"
                     label="Slot na paměťovky"
                     name="memorySlot"
                     value={fetchedData.component.memorySlot}
                     onChange={(e) => onChangeHandler(e, true)}
                  />
               </div>
               <h2>Display</h2>

               <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-4">
                  <TextField
                     key="displayType"
                     required
                     id="outlined-required"
                     className="input"
                     label="Typ displaye"
                     name="displayType"
                     value={fetchedData.component.displayType}
                     onChange={(e) => onChangeHandler(e, true)}
                  />
                  <TextField
                     key="displaySize"
                     required
                     id="outlined-required"
                     className="input"
                     label="Velikost displaye"
                     name="displaySize"
                     value={fetchedData.component.displaySize}
                     onChange={(e) => onChangeHandler(e, true)}
                  />
                  <TextField
                     key="displayResolution"
                     required
                     id="outlined-required"
                     className="input"
                     label="Rozlišení displaye"
                     name="displayResolution"
                     value={fetchedData.component.displayResolution}
                     onChange={(e) => onChangeHandler(e, true)}
                  />

                  <TextField
                     key="displayFrequency"
                     required
                     id="outlined-required"
                     className="input"
                     label="Frekvence displaye"
                     name="displayFrequency"
                     value={fetchedData.component.displayFrequency}
                     onChange={(e) => onChangeHandler(e, true)}
                  />
                  <TextField
                     key="displayNits"
                     required
                     id="outlined-required"
                     className="input"
                     label="Nity displaye"
                     name="displayNits"
                     value={fetchedData.component.displayNits}
                     onChange={(e) => onChangeHandler(e, true)}
                  />
                  <TextField
                     key="displayPpi"
                     required
                     id="outlined-required"
                     className="input"
                     label="PPI displaye"
                     name="displayPpi"
                     value={fetchedData.component.displayPpi}
                     onChange={(e) => onChangeHandler(e, true)}
                  />
                  {fetchedData?.displayDolbyVision && (
                     <TextField
                        key="displayDolbyVision"
                        required
                        id="outlined-required"
                        className="input"
                        label="Dolby Vision"
                        name="displayDolbyVision"
                        value={fetchedData.component.displayDolbyVision}
                        onChange={(e) => onChangeHandler(e, true)}
                     />
                  )}
                  {fetchedData?.displayHdr && (
                     <TextField
                        key="displayHdr"
                        required
                        id="outlined-required"
                        className="input"
                        label="HDR"
                        name="displayHdr"
                        value={fetchedData.component.displayHdr}
                        onChange={(e) => onChangeHandler(e, true)}
                     />
                  )}
                  <TextField
                     key="displayRatio"
                     required
                     id="outlined-required"
                     className="input"
                     label="Poměr displeje"
                     name="displayRatio"
                     value={fetchedData.component.displayRatio}
                     onChange={(e) => onChangeHandler(e, true)}
                  />
                  <TextField
                     key="displayToBodyRatio"
                     required
                     id="outlined-required"
                     className="input"
                     label="Poměr displeje k tělu"
                     name="displayToBodyRatio"
                     value={fetchedData.component.displayToBodyRatio}
                     onChange={(e) => onChangeHandler(e, true)}
                  />
                  <TextField
                     key="displayProtection"
                     className="input"
                     label="Krytí displaye"
                     name="displayProtection"
                     value={fetchedData.component.displayProtection}
                     onChange={(e) => onChangeHandler(e, true)}
                  />
                  <TextField
                     key="displayBezel"
                     className="input"
                     label="Tloušťka rámečku (mm bez jednotek)"
                     name="displayBezel"
                     value={fetchedData.component.displayBezel}
                     onChange={(e) => onChangeHandler(e, true)}
                  />
               </div>

               <h2>Kamery</h2>
               <h3>Hlavní kamery</h3>
               <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-4">
                  {fetchedData.component.mainCameras &&
                     fetchedData.component.mainCameras.map((item, index) => (
                        <TextField
                           key={`maincam-${index + 1}`}
                           required
                           id="outlined-required"
                           className="input"
                           label={`Hlavní kamera č.${index + 1}`}
                           name="mainCameras"
                           value={fetchedData.component.mainCameras[index]}
                           onChange={(e) => onChangeHandler(e, index)}
                        />
                     ))}
                  <TextField
                     key="maincamFeatures"
                     required
                     id="outlined-required"
                     className="input"
                     label="Fíčury hlavních kamer"
                     name="mainCamerasFeatures"
                     value={fetchedData.component.mainCamerasFeatures}
                     onChange={(e) => onChangeHandler(e, true)}
                  />
                  <TextField
                     key="maincamVid"
                     required
                     id="outlined-required"
                     className="input"
                     label="Video hlavních kamer"
                     name="mainCamerasVideo"
                     value={fetchedData.component.mainCamerasVideo}
                     onChange={(e) => onChangeHandler(e, true)}
                  />
               </div>
               <h3>Přední kamera (selfie)</h3>

               <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-4">
                  {fetchedData.component.frontCameras &&
                     fetchedData.component.frontCameras.map((item, index) => (
                        <TextField
                           key={`selfiecam-${index + 1}`}
                           required
                           id="outlined-required"
                           className="input"
                           label={`Selfie kamera č.${index + 1}`}
                           name="frontCameras"
                           value={fetchedData.component.frontCameras[index]}
                           onChange={(e) => onChangeHandler(e, index)}
                        />
                     ))}
                  <TextField
                     key="frontCamerasFeatures"
                     required
                     id="outlined-required"
                     className="input"
                     label="Fíčury selfie kamer"
                     name="frontCamerasFeatures"
                     value={fetchedData.component.frontCamerasFeatures}
                     onChange={(e) => onChangeHandler(e, true)}
                  />
                  <TextField
                     key="frontCamerasVideo"
                     required
                     id="outlined-required"
                     className="input"
                     label="Video selfie kamer"
                     name="frontCamerasVideo"
                     value={fetchedData.component.frontCamerasVideo}
                     onChange={(e) => onChangeHandler(e, true)}
                  />
               </div>

               <h2>Konektivita, výstupy</h2>

               <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-4">
                  <TextField
                     key="speakers"
                     required
                     id="outlined-required"
                     className="input"
                     label="Repráky"
                     name="speakers"
                     value={fetchedData.component.speakers}
                     onChange={(e) => onChangeHandler(e, true)}
                  />
                  <TextField
                     key="jack"
                     required
                     id="outlined-required"
                     className="input"
                     label="Jack"
                     name="jack"
                     value={fetchedData.component.jack}
                     onChange={(e) => onChangeHandler(e, true)}
                  />
                  <TextField
                     key="wlan"
                     required
                     id="outlined-required"
                     className="input"
                     label="Wifi"
                     name="wlan"
                     value={fetchedData.component.wlan}
                     onChange={(e) => onChangeHandler(e, true)}
                  />
                  <TextField
                     key="bluetooth"
                     required
                     id="outlined-required"
                     className="input"
                     label="Bluetooth"
                     name="bluetooth"
                     value={fetchedData.component.bluetooth}
                     onChange={(e) => onChangeHandler(e, true)}
                  />
                  <TextField
                     key="gps"
                     required
                     id="outlined-required"
                     className="input"
                     label="GPS"
                     name="gps"
                     value={fetchedData.component.gps}
                     onChange={(e) => onChangeHandler(e, true)}
                  />
                  <TextField
                     key="nfc"
                     required
                     id="outlined-required"
                     className="input"
                     label="NFC"
                     name="nfc"
                     value={fetchedData.component.nfc}
                     onChange={(e) => onChangeHandler(e, true)}
                  />
                  <TextField
                     key="radio"
                     required
                     id="outlined-required"
                     className="input"
                     label="Rádio FM"
                     name="radio"
                     value={fetchedData.component.radio}
                     onChange={(e) => onChangeHandler(e, true)}
                  />
                  <TextField
                     key="usb"
                     required
                     id="outlined-required"
                     className="input"
                     label="USB"
                     name="usb"
                     value={fetchedData.component.usb}
                     onChange={(e) => onChangeHandler(e, true)}
                  />
                  <TextField
                     key="sensors"
                     required
                     id="outlined-required"
                     className="input"
                     label="Senzory"
                     name="sensors"
                     value={fetchedData.component.sensors}
                     onChange={(e) => onChangeHandler(e, true)}
                  />
               </div>

               <h2>Tělo telefonu</h2>
               <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-4">
                  <TextField
                     key="body"
                     required
                     id="outlined-required"
                     className="input"
                     label="Zpracování telefonu"
                     name="body"
                     value={fetchedData.component.body}
                     onChange={(e) => onChangeHandler(e, true)}
                  />
                  <TextField
                     key="bodyOther"
                     required
                     id="outlined-required"
                     className="input"
                     label="Další bonusy k tělu"
                     name="bodyOther"
                     value={fetchedData.component.bodyOther}
                     onChange={(e) => onChangeHandler(e, true)}
                  />
                  <TextField
                     key="stylus"
                     required
                     id="outlined-required"
                     className="input"
                     label="Podpora stylusu"
                     name="stylus"
                     value={fetchedData.component.stylus}
                     onChange={(e) => onChangeHandler(e, true)}
                  />
                  <TextField
                     key="waterResistant"
                     required
                     id="outlined-required"
                     className="input"
                     label="voděodolnost"
                     name="waterResistant"
                     value={fetchedData.component.waterResistant}
                     onChange={(e) => onChangeHandler(e, true)}
                  />
                  <TextField
                     key="width"
                     required
                     id="outlined-required"
                     className="input"
                     label="Šířka (mm)"
                     name="width"
                     value={fetchedData.component.width}
                     onChange={(e) => onChangeHandler(e, true)}
                  />
                  <TextField
                     key="height"
                     required
                     id="outlined-required"
                     className="input"
                     label="Výška (mm)"
                     name="height"
                     value={fetchedData.component.height}
                     onChange={(e) => onChangeHandler(e, true)}
                  />
                  <TextField
                     key="depth"
                     required
                     id="outlined-required"
                     className="input"
                     label="Tloušťka (mm)"
                     name="depth"
                     value={fetchedData.component.depth}
                     onChange={(e) => onChangeHandler(e, true)}
                  />
                  <TextField
                     key="weight"
                     required
                     id="outlined-required"
                     className="input"
                     label="Váha (gramy)"
                     name="weight"
                     value={fetchedData.component.weight}
                     onChange={(e) => onChangeHandler(e, true)}
                  />
               </div>

               <h2>Fíčury</h2>

               <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-4">
                  {fetchedData.component.features &&
                     fetchedData.component.features.map((item, index) => (
                        <TextField
                           key={`feature-${index + 1}`}
                           required
                           id="outlined-required"
                           className="input"
                           label={`Fíčura telefonu č.${index + 1}`}
                           name="features"
                           value={fetchedData.component.features[index]}
                           onChange={(e) => onChangeHandler(e, index)}
                        />
                     ))}
               </div>

               <h2>Baterie</h2>
               <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-4">
                  <TextField
                     key="batery"
                     required
                     id="outlined-required"
                     className="input"
                     label="Baterie"
                     name="battery"
                     value={fetchedData.component.battery}
                     onChange={(e) => onChangeHandler(e, true)}
                  />
                  {fetchedData.component.charging &&
                     fetchedData.component.charging.map((item, index) => (
                        <TextField
                           key={`battery-charging-${index + 1}`}
                           required
                           id="outlined-required"
                           className="input"
                           label={`Nabíjení řádek č.${index + 1}`}
                           name="charging"
                           value={fetchedData.component.charging[index]}
                           onChange={(e) => onChangeHandler(e, index)}
                        />
                     ))}

                  {fetchedData.component.wirelessCharging &&
                     fetchedData.component.wirelessCharging.map((item, index) => (
                        <TextField
                           key={`battery-wirelessCharging-${index + 1}`}
                           required
                           id="outlined-required"
                           className="input"
                           label={`Bezdrátové nabíjení řádek č.${index + 1}`}
                           name="wirelessCharging"
                           value={fetchedData.component.wirelessCharging[index]}
                           onChange={(e) => onChangeHandler(e, index)}
                        />
                     ))}
                  <TextField
                     key="colors"
                     required
                     id="outlined-required"
                     className="input"
                     label="Barevné varianty"
                     name="colors"
                     value={fetchedData.component.colors}
                     onChange={(e) => onChangeHandler(e, true)}
                  />
                  <TextField
                     key="sarEu"
                     required
                     id="outlined-required"
                     className="input"
                     label="SAR vyrařování"
                     name="sarEu"
                     value={fetchedData.component.sarEu}
                     onChange={(e) => onChangeHandler(e, true)}
                  />
               </div>

               <h2>Benchmarky</h2>

               <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-4">
                  {fetchedData.component.benchmarks &&
                     fetchedData.component.benchmarks.map((item, index) => (
                        <TextField
                           key={`bench-${index + 1}`}
                           required
                           id="outlined-required"
                           className="input"
                           label={`Benchmark řádek č.${index + 1}`}
                           name="benchmarks"
                           value={fetchedData.component.benchmarks[index]}
                           onChange={(e) => onChangeHandler(e, index)}
                        />
                     ))}
               </div>
            </div>
         )}
      </>
   );
};
