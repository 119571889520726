import { extractNumber } from "./utils/utils";

async function alza(url) {
   let finalUrl = url;
   if (!url.includes("alza")) {
      alert("Špatná url!");
      return;
   }
   if (!finalUrl.includes("#parametry")) {
      finalUrl += "#parametry"
   }
   try {
      const req = await fetch(url);
      const html = await req.text();
      const DATA = await grabber(html, url);
      return DATA;
   } catch (err) {
      console.log(err);
      return false;
   }
}

function grabber(html, url) {
   //console.log(html);
   const parser = new DOMParser();
   const doc = parser.parseFromString(html, "text/html");

   if (!doc.querySelector(".params .allpar")) {
      alert("Nenalezena relevantní data");
      return;
   }

   const getStr = (query) => {
      let target = doc.querySelector(query);
      if (target) {
         return target.innerText.replace(/\n/g, "").trim();
      } else {
         console.log("Nenalezen element: ", query);
         return undefined;
      }
   };

   const alzaParamsObject = () => {
      const groups = doc.querySelectorAll('.params .allpar .groupx');
      const result = {};

      groups.forEach(group => {
         // Extrakce názvu kategorie
         const categoryName = group.querySelector('h3').innerText.trim();
         const parameters = {};

         // Výběr všech parametrů v kategorii
         const rows = group.querySelectorAll('.row');

         rows.forEach(row => {
            // Získání názvu parametru z odkazu uvnitř třídy .name
            let nameElement = row.querySelector('.name a');
            if (!nameElement) {
               nameElement = row.querySelector('.name');
            }

            let key = nameElement ? nameElement.innerText.trim() : '';

            // console.log(row.querySelector('.value').childNodes, "nooody")

            // Hodnota parametru
            let valueElement = row.querySelector('.value');

            let value = ""


            for (const node of Array.from(valueElement.childNodes)) {
               // console.log(node, "node")
               if (node.nodeType === Node.TEXT_NODE || node.nodeType === Node.ELEMENT_NODE && node.tagName === 'A') {
                  if (node.tagName === 'A') {
                     node.querySelector('span').remove();

                  }
                  value += node.textContent;
               }
            }

            if (key) {
               parameters[key] = value;
            }
         });

         // Přidání kategorie do výsledného objektu
         result[categoryName] = parameters;
      });

      return result || "";
   }

   const params = alzaParamsObject();

   console.log(params)

   const findUnnamed = (queryString) => {
      let path = queryString.split('.');  // Rozdělíme cestu na jednotlivé části
      if (path.length > 2) {
         path = [path[0], path.slice(1).join('.')];  // Pokud je cesta delší než 2, spojíme všechny kromě prvního prvku
      }
      let current = params;

      // Procházíme objekt postupně podle cesty
      for (const part of path) {
         if (current[part] === undefined) {
            return undefined;  // Vrátí undefined, pokud cesta neexistuje
         }
         current = current[part];
      }

      return current;

   };


   const extractCPUModel = (string) => {
      let cpu = string.replace("Intel Core i3", "").replace("Intel Core i5", "").replace("Intel Core i7", "").replace("Intel Core i9", "").replace("AMD Ryzen 3", "").replace("AMD Ryzen 5", "").replace("AMD Ryzen 7", "").replace("AMD Ryzen 9", "").trim().split(" ")
      return cpu[0]
   }
   const extractProductCode = () => {
      return doc.querySelector("[data-testid='more-info-product-code'] [data-testid='value']").innerText || "";
   };

   let json = {
      title: getStr("h1"),
      codeName: extractProductCode(),

      width: +extractNumber(findUnnamed("Rozměry.Šířka")),
      height: +extractNumber(findUnnamed("Rozměry.Výška maximální")),
      depth: +extractNumber(findUnnamed("Rozměry.Hloubka")),
      weight: +extractNumber(findUnnamed("Hmotnost.Hmotnost")) * 1000 || "",

      displayType: findUnnamed("Displej.Typ panelu"),
      displayResolution: findUnnamed("Displej.Maximální rozlišení"),
      displaySize: extractNumber(findUnnamed("Displej.Úhlopříčka displeje")),
      displayTouch: findUnnamed("Výbava.Výbava")?.includes("Dotykový displej") ? "Ano" : "",

      displaySurface: findUnnamed("Displej.Typ displeje"),
      displayFrequency: extractNumber(findUnnamed("Displej.Obnovovací frekvence displeje")),
      displayRatio: findUnnamed("Displej.Poměr stran"),
      displayNits: extractNumber(findUnnamed("Displej.Svítivost")),

      webcam: findUnnamed("Výbava.Webkamera"),
      webcamCover: "",

      cpu: findUnnamed("Procesor.Typ procesoru"),
      cpuModel: extractCPUModel(findUnnamed("Procesor.Modelové označení procesoru")),
      cpuFrequency: findUnnamed("Procesor.Frekvence procesoru"),
      cpuCores: extractNumber(findUnnamed("Procesor.Počet jader procesoru")),
      cpuBoost: findUnnamed("Procesor.Core Boost Frekvence"),
      cpuTdp: findUnnamed("Procesor.TDP"),
      cpuCache: findUnnamed("Procesor.Cache procesoru"),
      cpuGen: findUnnamed("Procesor.Generace procesoru Intel") || findUnnamed("Procesor.Generace procesoru AMD"),

      gpu: findUnnamed("GPU značka/model.Značka grafické karty") + " " + findUnnamed("GPU značka/model.Model grafické karty"),
      gpuType: findUnnamed("Grafická karta.Typ grafické karty"),
      gpuCores: findUnnamed("Grafická karta.Počet jader GPU"),
      gpuMemory: findUnnamed("Grafická karta.Paměť grafické karty"),
      gpuFrequency: findUnnamed("Grafická karta.Boost frekvence"),
      gpuTgp: findUnnamed("Grafická karta.Maximální grafický příkon (TGP)"),

      ram: extractNumber(findUnnamed("Operační paměť.Velikost operační paměti RAM")),
      ramType: findUnnamed("Operační paměť.Typ paměti"),
      ramFrequency: findUnnamed("Operační paměť.Frekvence paměti"),

      diskHdd: extractNumber(findUnnamed("Pevný disk.HDD Kapacita")?.split(" ")[0]),
      diskSsd: extractNumber(findUnnamed("Pevný disk.SSD Kapacita")?.split(" ")[0]),
      diskSsdType: findUnnamed("Pevný disk.Rozhraní disku"),
      diskSlotsTotal: extractNumber(findUnnamed("Pevný disk.Celkový počet slotů M.2")),
      diskSlotsUsed: extractNumber(findUnnamed("Pevný disk.Počet osazených slotů M.2")),
      
      batteryCapacity: extractNumber(findUnnamed("Baterie.Kapacita baterie")),
      batteryCells: extractNumber(findUnnamed("Baterie.Počet článků")),
      batteryDuration: findUnnamed("Baterie.Maximální výdrž baterie"),
      usbcPd: findUnnamed("Baterie.Nabíjení přes USB-C"),
      chargingCurrent: findUnnamed("Baterie.Nabíjecí příkon"),
      
      hdmi: `${findUnnamed("Rozhraní.Grafické výstupy")?.includes("HDMI") ? "Ano" : ""}`,
      usb2: extractNumber(findUnnamed("Rozhraní.USB 2.0")),
      usb3: extractNumber(findUnnamed("Rozhraní.USB 3.2 Gen 1 (USB 3.0)")),
      usbC: extractNumber(findUnnamed("Rozhraní.USB-C")),
      thunderbolt: extractNumber(findUnnamed("Rozhraní.Thunderbolt")),
      bluetooth: extractNumber(findUnnamed("Rozhraní.Bluetooth")),
      wifi: findUnnamed("Rozhraní.Verze WiFi"),
      wifiBand: findUnnamed("Rozhraní.WiFi"),
      rj45: findUnnamed("Rozhraní.Síťové")?.includes("RJ-45") ? "Ano" : "Ne",
      //opticalDrive: findUnnamed("Optická mechanika") === "Bez mechaniky" ? "Ne" : "Ano",
      //jack: findUnnamed("Rozhraní.Audio")?.includes("Jack") ? "Ano" : "Ne",
      
      network: "",
      memorySlot: findUnnamed("Výbava.Výbava")?.includes("Čtečka paměťových karet") ? "Ano" : "",
      chasiMaterial: findUnnamed("Konstrukce.Materiál konstrukce"),
      stereoSpeakers: "",
      keyboardBacklit: findUnnamed("Výbava.Výbava")?.includes("Podsvícená klávesnice") ? `Ano${findUnnamed("Výbava.Podsvícení") === "RGB" ? ", RGB" : ""}` : "",
      keyboardNumeric: findUnnamed("Výbava.Výbava").includes("Numerická klávesnice") ? "Ano" : "",
      microphone: "Ano",
      jack: findUnnamed("Rozhraní.Audio")?.includes("Jack") ? "Ano" : "",
      tpm2: findUnnamed("Výbava.Výbava").includes("TPM") ? "Ano" : "",
      sensors: "",
      os: findUnnamed("Operační systém.Operační systém"),
      alzaLink: url.replace("#parametry", ""),
   };

   console.log(json);
   return json;
}

export default alza;
